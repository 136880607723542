import payroll from '../downloads/Inserat2024Payrollspezialist.pdf'
import wirtschaft from '../downloads/Inserat2024AbacusHRConsultant.pdf'
import lehrling from '../downloads/LehrstelleEntwicklerDigitalesBusinessEFZ2025.pdf'
//import lehrling2 from '../downloads/Lehrling2023.pdf'

export const jobs = [
    // Entwickler / Consultant ausblenden
    {
        title: 'Wirtschaftsinformatiker:in / Abacus ERP HR-Consultant',
        text: 'Eintritt nach Vereinbarung',
        file: wirtschaft,
        fileType: 'PDF'
    },
    // Wirtschaftsinformatiker / HR-Projektleiter ausblenden
    {
        title: 'Payrollspezialist:in / Abacus ERP Lohn-Consultant',
        text: 'Eintritt nach Vereinbarung',
        file: payroll,
        fileType: 'PDF'
    },
    //Lehrstellen ausblenden
    {
        title: 'Lehrstelle als Entwickler:in digitales Business EFZ',
        text: 'Start August 2025',
        file: lehrling,
        fileType: 'PDF'
    },
  /*{
        title: 'Lehrstelle als Informatiker/in Applikationsentwicklung EFZ',
        text: 'Lehrbeginn Sommer 2023',
        file: lehrling2,
        fileType: 'PDF'
    }*/
]